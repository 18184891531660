<template>
  <div>
    <base-header class="pb-6" type="">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">Venue Specialists Reports</h6>
        </div>
      </div>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--6"  v-loading="loading">
      <div class="card-deck flex-column flex-xl-row">
        <card header-classes="bg-transparent">
          <div slot="header" class="row align-items-center">
            <div class="col">
              <h5 class="h3 mb-0">Total Leads</h5>
            </div>
          </div>
          <bar-chart :height="500" ref="bigChart" :chart-data="VenueSpecialistsCount" :extra-options="VenueSpecialistsChartOptions('total')">
          </bar-chart>
        </card>
      </div>
      <div class="card-deck flex-column flex-xl-row">
        <card header-classes="bg-transparent">
          <div slot="header" class="row align-items-center">
            <div class="col">
              
              <h5 class="h3 mb-0">Average Recommendations <h6 class="text-uppercase text-muted ls-1 mb-1 d-inline-block">Per Lead</h6></h5>
            </div>
          </div>
          <bar-chart :height="350" ref="bigChart" :chart-data="AverageRecommendations" :extra-options="VenueSpecialistsChartOptions('average')">
          </bar-chart>
        </card>
      </div>
      <!-- End charts-->
    </div>
  </div>
</template>
<script>
// Charts
import BarChart from "@/components/Charts/BarChart";

// Components

import ReportsService from "@/services/reports.service";
import { mapState } from "vuex";

export default {
  data() {
    return {
      loading: true,
    };
  },
  components: {
    BarChart,
  },
  async created() {
    await this.$store.dispatch("reports/getVenueSpecialists");
    this.loading = false;
  },
  computed: mapState({
    VenueSpecialistsCount(state) {
      return ReportsService.formatMultipleKeyValues(state.reports.venue_specialists.total || [], 'venue_specialist_name');
    },
    AverageRecommendations(state) {
      return ReportsService.formatVenueSpecialistsCount(state.reports.venue_specialists.average_recommendations || []);
    },
  }),
  methods: {
    VenueSpecialistsChartOptions(type) {
      let showLegend = false;
      if (type === 'total') {
        showLegend = true;
      }
      return {
        legend: {
          display: showLegend,
          position: 'bottom',
        },
        scales: {
          xAxes: [{
            stacked: false,
            categoryPercentage: 0.5,
            barPercentage: 1,
            ticks: {
              callback: function(label, index, labels) {
                return label.substr(0, 3);
              },
            },
          }],
          yAxes: [{
            stacked: false,
            ticks: {
              callback: function(label) {
                return new Intl.NumberFormat( 'en-US', { maximumFractionDigits: 1,notation: "compact" , compactDisplay: "short" }).format(label);
              },
            },
          }],
        },
      }; 
    },
  },
};
</script>
<style></style>
